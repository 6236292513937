import { useEffect } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useForm, SubmitHandler } from "react-hook-form";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { Button, Value } from "@pnpm-monorepo/core/src/ui/components";
import TextareaWrapper from "../../../../components/FormWrappers/TextareaWrapper";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";
import RadioGroupWrapper from "../../../../components/FormWrappers/RadioGroupWrapper";

import { Barcode } from "./Barcode";

import {
  offerActionTextsQueryOptions,
  offerWorthsQueryOptions,
  offerPrepositionsQueryOptions,
  offerServiceTextsQueryOptions,
  useUpdateOfferMutation,
} from "../-api/queryOptions";
import {
  BarcodeTypes,
  OfferType,
  OfferServiceTextType,
} from "../../../../utils/types";
import { cs } from "@pnpm-monorepo/core/src/utils";
import { useSnackbar } from "notistack";

export function OfferFields({ offer }: { offer: OfferType }) {
  const { product, company } = offer;

  const { enqueueSnackbar } = useSnackbar()

  const updateOfferMutation = useUpdateOfferMutation(offer.id);

  // TODO: make this mor efficient... it flickers first time...
  const offerActionTextsQuery = useSuspenseQuery(
    offerActionTextsQueryOptions()
  );
  const offerWorthsQuery = useSuspenseQuery(offerWorthsQueryOptions());
  const offerPrepositionsQuery = useSuspenseQuery(
    offerPrepositionsQueryOptions()
  );
  const offerServiceTextsQuery = useSuspenseQuery(
    offerServiceTextsQueryOptions(company.id)
  );
  const offerActionTexts = offerActionTextsQuery.data;
  const offerWorths = offerWorthsQuery.data;
  const offerPrepositions = offerPrepositionsQuery.data;
  const offerServiceTexts = offerServiceTextsQuery.data;

  const fixedOfferServiceTexts = offerServiceTexts.filter(
    (x: OfferServiceTextType) => Number(x.id) < 1000
  );
  const commonOfferServiceTexts = offerServiceTexts.filter(
    (x: OfferServiceTextType) => Number(x.id) > 999
  );

  const { control, handleSubmit, watch, setValue, reset } = useForm<OfferType>({
    defaultValues: {
      id: offer.id,
      originalPrice: offer.originalPrice ?? "",
      restrictions: offer.restrictions ?? "",
      information: offer.information ?? "",
      offerActionLink: offer.offerActionLink ?? "",
      offerActionText: offer.offerActionText,
      offerWorth: offer.offerWorth,
      offerWorthText: offer.offerWorthText ?? "",
      offerPreposition: offer.offerPreposition,
      offerService:
        offer.offerService ?? fixedOfferServiceTexts
          ? fixedOfferServiceTexts.find(
              (x: OfferServiceTextType) => x.name === offer.offerServiceText
            )
          : undefined,
      offerServiceText: offer.offerServiceText ?? "",
      barcode: offer.barcode ?? "",
      barcodeType: offer.barcodeType ? offer.barcodeType : BarcodeTypes[0],
    },
  });

  const watchBarcode = watch("barcode");
  const watchBarcodeType = watch("barcodeType");
  const watchOfferActionText = watch("offerActionText");
  const watchOfferWorth = watch("offerWorth");
  const watchOfferService = watch("offerService");

  useEffect(() => {
    const offerWorthText = watchOfferWorth?.name;
    if (offerWorthText) setValue("offerWorthText", "");
  }, [watchOfferWorth, setValue]);

  useEffect(() => {
    const offerServiceText = watchOfferService?.name;
    if (offerServiceText) setValue("offerServiceText", "");
  }, [watchOfferService, setValue]);

  const onSubmit: SubmitHandler<OfferType> = (data) => {
    return updateOfferMutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: (newData) => {
          enqueueSnackbar('"Erbjudande" uppdaterad')
          reset(newData);
        },
        onError : (error) => {
          console.error(error)
          enqueueSnackbar(`Något gick fel: ${error.message}.`, { variant: "error"})
        }
      }
    );
  };

  const handleCommonOfferServiceText = (name: string) => {
    setValue("offerServiceText", name);
  };

  return (
    <>
      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Värde i erbjudandetexten
        </div>

        <div className="pt-4 flex gap-2 flex-wrap">
          <RadioGroupWrapper
            name="offerWorth"
            options={offerWorths}
            control={control}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>
        {!watchOfferWorth && (
          <div className="pt-4">
            <TextFieldWrapper
              name="offerWorthText"
              control={control}
              placeholder="Fritext..."
            />
          </div>
        )}
      </div>
      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Preposition i erbjudandetexten
        </div>

        <div className="pt-4 flex gap-2 flex-wrap">
          <RadioGroupWrapper
            name="offerPreposition"
            options={offerPrepositions}
            control={control}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Tjänst/Produkt i erbjudandetexten
        </div>

        <div className="pt-4 flex gap-2 flex-wrap">
          <RadioGroupWrapper
            name="offerService"
            options={fixedOfferServiceTexts}
            control={control}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>

        {!watchOfferService && (
          <div className="mt-2">
            {commonOfferServiceTexts && (
              <div className=" pt-4 flex gap-2 flex-wrap">
                {commonOfferServiceTexts?.map((x) => {
                  return (
                    <div
                      key={x.id}
                      className={cs(
                        "transition-all duration-300 ease-in-out",
                        "text-label-small",
                        "items-center box-content cursor-pointer flex justify-center px-4 h-4 hover:shadow-mm-1",
                        "bg-surface hover:bg-surface-container-low",
                        "border-outline rounded-small border-[1px]"
                      )}
                      onClick={() => handleCommonOfferServiceText(x.name)}
                    >
                      {x.name}
                    </div>
                  );
                })}
              </div>
            )}

            <div className="pt-4">
              <TextFieldWrapper
                name="offerServiceText"
                control={control}
                placeholder="Fritext..."
              />
            </div>
          </div>
        )}
      </div>
      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Streckkod
        </div>

        <div className="flex flex-col">
          <div className="pt-2 flex flex-row items-center gap-2">
            <SelectWrapper
              name="barcodeType"
              control={control}
              className="min-w-32"
              options={BarcodeTypes}
              getOptionLabel={(option: Value) => option}
            />
            -
            <TextFieldWrapper
              name="barcode"
              control={control}
              className="grow"
            />
          </div>
          <div className="flex">
            <Barcode barcode={watchBarcode} barcodeType={watchBarcodeType} />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Text för "Läs mer om erbjudandet"
        </div>

        <div className="pt-2">
          <TextareaWrapper name="information" control={control} />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Reservationstext på erbjudandet
        </div>

        <div className="pt-2">
          <TextareaWrapper name="restrictions" control={control} />
        </div>
      </div>

      {/* {product?.productCategory?.offerActionId === 2 && (
        <> */}
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
            {product?.productCategory?.offerActionId === 2 ? "Knapptext på erbjudande" : "Knapptext på kompletterande informationslänk"}
            </div>

            <div className="pt-4 flex gap-2 flex-wrap">
              <RadioGroupWrapper
                name="offerActionText"
                options={offerActionTexts}
                control={control}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>
          </div>
          {watchOfferActionText ? (
            <>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Länkadress
                </div>

                <div className="pt-2">
                  <TextFieldWrapper name="offerActionLink" control={control} />
                </div>
              </div>

              <div className="text-label-small mb-2 p-3 bg-surface-container-lowest rounded-medium">
                Länkadressen kan användas för att länka användaren till en
                webbsida, en e-postadress eller ett telefonnummer.
                <ul className="mt-2 list-disc list-outside pl-4">
                  <li>
                    Länka till webbsida - inled med http: eller https:
                    <br />
                    Ex:{" "}
                    <span className="text-blue-700">
                      https://www.mobilkupongen.se
                    </span>
                  </li>
                  <li>
                    Länka till e-postadress - inled med mailto:
                    <br />
                    Ex{" "}
                    <span className="text-blue-700">
                      mailto:info@mobilkupongen.se
                    </span>
                  </li>
                  <li>
                    Länka till telefonnummer - inled med tel:
                    <br />
                    Ex <span className="text-blue-700">tel:0101150100</span>
                  </li>
                </ul>
              </div>
            </>
          ) : null}
        {/* </>
      )} */}

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Ordinarie pris
        </div>
        <div className="pt-2">
          <TextFieldWrapper name="originalPrice" control={control} />
        </div>
      </div>

      <div id="offer-action_offer" className="flex mb-4 justify-end gap-2">
        <Button
          variant="filled"
          onClick={handleSubmit(onSubmit)}
          disabled={updateOfferMutation.isPending}
        >
          Spara
        </Button>
      </div>
    </>
  );
}
