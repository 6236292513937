import { useEffect, useRef, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useForm, SubmitHandler } from "react-hook-form";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { Button, LinkBox, Value } from "@pnpm-monorepo/core/src/ui/components";
import RadioGroupWrapper from "../../../../components/FormWrappers/RadioGroupWrapper";

import {
  offerPlacesQueryOptions,
  offerProductsQueryOptions,
  companyStoresQueryOptions,
  companyIndustriesQueryOptions,
  useUpdateOfferProductPlacesMutation,
} from "../-api/queryOptions";

import CheckboxGroupWrapper from "../../../../components/FormWrappers/CheckboxGroupWrapper";
import SelectWrapper from "../../../../components/FormWrappers/SelectWrapper";
import {
  ContractProductPlaceType,
  OfferType,
  StoreType,
} from "../../../../utils/types";
import { useSnackbar } from "notistack";

export function ProductPlaceFields({ offer }: { offer: OfferType }) {
  const { product, contractProductPlaces, company, stores, industry } = offer;
  const [allPlace, setAllPlace] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const [filteredProductPlaces, setFilteredProductPlaces] = useState<
    ContractProductPlaceType[]
  >([]);
  const updateOfferMutation = useUpdateOfferProductPlacesMutation(offer.id);

  const offerProductsQuery = useSuspenseQuery(
    offerProductsQueryOptions(offer.id)
  );
  const offerPlacesQuery = useSuspenseQuery(offerPlacesQueryOptions(offer.id));
  const companyStoresQuery = useSuspenseQuery(
    companyStoresQueryOptions(company.id)
  );
  const companyIndustriesQuery = useSuspenseQuery(
    companyIndustriesQueryOptions(company.id)
  );

  const products = offerProductsQuery.data;
  const places = offerPlacesQuery.data;
  const stres = companyStoresQuery.data;
  const industries = companyIndustriesQuery.data;

  const { control, handleSubmit, watch, reset, setValue } = useForm<OfferType>({
    defaultValues: {
      id: offer.id,
      product: product,
      industry: industry || (industries.length ? industries[0] : null),
      contractProductPlaces: places
        ? places.filter((x: ContractProductPlaceType) =>
            contractProductPlaces?.some(
              (item) => item.id === x.id && product?.id === x.product?.id
            )
          )
        : undefined,
      stores: stres
        ? stres.filter((x: StoreType) =>
            stores?.some((item) => item.id === x.id)
          )
        : undefined,
    },
  });

  const watchContractProductPlaces = watch("contractProductPlaces");
  const watchProduct = watch("product");

  useEffect(() => {
    if (watchProduct !== undefined) {
      const filtered = places.filter(
        (x: ContractProductPlaceType) => x.product?.id === watchProduct?.id
      );
      setFilteredProductPlaces(filtered);

      if(filtered?.length === watchContractProductPlaces?.length) {
        setAllPlace(true);
      } else {
        setAllPlace(false);
      }

      if (
        watchContractProductPlaces !== null &&
        watchContractProductPlaces !== undefined
      ) {
        const newContractProductPlaces = watchContractProductPlaces.filter(
          (item) => filtered.some((filteredItem) => filteredItem.id === item.id)
        );


        if (
          newContractProductPlaces.length !== watchContractProductPlaces.length
        ) {
          setValue("contractProductPlaces", newContractProductPlaces);
        } 
      }
    }
  }, [places, watchProduct, watchContractProductPlaces, setValue]);

  const onSubmit: SubmitHandler<OfferType> = (data) => {
    return updateOfferMutation.mutate(data, {
      onSuccess: () => {
        enqueueSnackbar('"Produkt, placering och kategori" uppdaterad');
        reset(data);
      },
      onError : (error) => {
        console.error(error)
        enqueueSnackbar(`Något gick fel: ${error.message}.`, { variant: "error"})
      }
    });
  };

  const toggleAll = () => {
    const newAllPlaceState = !allPlace;
    const newContractProductPlaces = newAllPlaceState
      ? filteredProductPlaces
      : [];
    setValue("contractProductPlaces", newContractProductPlaces);
  };

  return (
    <>
      <div className="mb-4">
        <div className="text-label-medium text-on-surface-variant">Produkt</div>
        <div className="pt-4 flex gap-2 flex-wrap">
          <RadioGroupWrapper
            name="product"
            options={products}
            control={control}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="flex items-center gap-2 text-label-medium text-on-surface-variant">
          <div className="text-label-medium text-on-surface-variant">
            Område
          </div>
          {filteredProductPlaces.length > 2 && (
            <button onClick={toggleAll} className="">
              <LinkBox> {allPlace ? "Avmarkera" : "Markera alla"}</LinkBox>
            </button>
          )}
        </div>

        {filteredProductPlaces.length ? (
          <div className="pt-4 flex gap-2 flex-wrap">
            <CheckboxGroupWrapper
              name="contractProductPlaces"
              options={filteredProductPlaces}
              control={control}
              getOptionLabel={(option) => {
                return (
                  <>
                    {option.place?.name}{" "}
                    {option.contract ? (
                      <>({option.contract.number})</>
                    ) : undefined}
                  </>
                );
              }}
              isOptionEqualToValue={(option, value) =>
                value?.some((item) => item.id === option.id)
              }
            />
          </div>
        ) : (
          <>
            <span className="text-label-medium">Du måste välja en produkt</span>
          </>
        )}
      </div>
      <div className="mb-4">
        <div className="text-label-medium text-on-surface-variant">Butik</div>

        <div className="pt-4 flex gap-2 flex-wrap">
          <CheckboxGroupWrapper
            name="stores"
            options={stres}
            control={control}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              value?.some((item) => item.id === option.id)
            }
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="text-label-medium text-on-surface-variant">
          Kategori
        </div>

        <div className="pt-2">
          <SelectWrapper
            name="industry"
            control={control}
            className="min-w-32"
            options={industries}
            getOptionLabel={(option: Value) => option?.name}
          />
        </div>
      </div>
      <div id="offer-action_offer" className="flex mb-4 justify-end gap-2">
        <Button
          variant="filled"
          onClick={handleSubmit(onSubmit)}
          disabled={updateOfferMutation.isPending}
        >
          Spara
        </Button>
      </div>
    </>
  );
}
